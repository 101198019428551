<script>
// Import Swiper Vue.js components
import {
    Swiper,
    SwiperSlide
} from 'swiper/vue';


// Import Swiper styles
import 'swiper/css';

// import required modules
import 'swiper/css/pagination';
import {
    Pagination
} from 'swiper/modules';

// import required modules
import 'swiper/css/navigation';
import {
    Navigation
} from 'swiper/modules';
import { useMenuStore } from '@/stores/menu';
import { useTranslationStore } from '@/stores/translation';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
      const menuStore = useMenuStore();
      const translationStore = useTranslationStore()
      const {best_sellings, popular} = storeToRefs(menuStore);
      const {isLoaded} = storeToRefs(translationStore);
      const getItemImage = (item) => {
        if (!item || !item.item_images) return "";
        const mainImage = item.item_images.find((img) => img.is_main);
        const path = mainImage ? mainImage.image : "";
        return "https://api.chds.com.au" + path;
        };

        const t = (label, modules) => {
            return translationStore.translate(label, modules);
        };

        onMounted(()=>{
            menuStore.loadItems();
        })
        return {
            modules: [Pagination, Navigation],
            best_sellings,
            getItemImage,
            isLoaded,
            popular,
            t,
        };
    },
};
</script>

<template>
    <div class="main">
      <div class="main-content" v-if="isLoaded">
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-12 col-sm-12 col-12 orderl-lg-1 order-md-2 order-sm-2 order-2">
              <div class="main-content-wrap">
                  <h4><Span class="wrap-text">{{ t('welcome_to_healthly:', ['homepage']) }}</Span> {{t('your_path_to_wellness', ['homepage'])}}
                    <span class="wrap-design">
                      <svg width="40" height="10" viewBox="0 0 40 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_509_79)">
                        <path d="M33.5014 9.5C37.0847 9.5 39.9998 7.48129 39.9998 4.99995C39.9998 2.51861 37.0847 0.5 33.5014 0.5C30.112 0.5 27.3219 2.30637 27.0298 4.6018H22.3527V3.6552L-0.000213623 3.6552V6.34446L22.3528 6.34446V5.39798H27.0299C27.3221 7.69352 30.112 9.5 33.5014 9.5ZM21.4896 5.54839L0.863079 5.54839V4.45161H21.4896V5.54839ZM27.866 4.99995C27.866 2.95778 30.3942 1.2964 33.5015 1.2964C36.6088 1.2964 39.137 2.95778 39.137 4.99995C39.137 7.04222 36.6088 8.7037 33.5015 8.7037C30.3942 8.7037 27.866 7.04222 27.866 4.99995Z" fill="#ff9c63"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_509_79">
                        <rect width="40" height="9" fill="white" transform="translate(-0.000213623 0.5)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </span>
                </h4>
                
                  
                  <p class="mb-0"> {{t('homepage_description', ['homepage'])}}</p>
              </div>
              <!-- <button class="btn theme-btn theme-outline-btn mt-5" > Order Now<span class="round-arrow ms-2"><i class="fa-solid fa-arrow-right-long"></i></span></button> -->
              <router-link  type="button" class="btn theme-btn theme-outline-btn mt-5" to="/ordernow" active-class="active"  @click="closeOffcanvas" >{{t('order_now', ['homepage'])}}<span class="round-arrow ms-2"><i class="fa-solid fa-arrow-right-long"></i></span></router-link>
              </div>
              <div class="col-lg-7 col-md-12 col-sm-12 col-12 orderl-lg-2 order-md-1 order-sm-1 order-1">
                <div class="main-img text-center">
                    <img src="@/assets/homepage/right-bg.bd9cedcd_2.png" class="image-right" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="our-food p-5 position-relative">

        <div class="food-content-warp">
            <div class="header-test-wrap">
                <p><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_312_6)">
                                <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118" />
                                <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118" />
                            </g>
                            <defs>
                                <clipPath id="clip0_312_6">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    {{ t('our_food', ['homepage']) }}
                    <span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_312_6)">
                                <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118" />
                                <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118" />
                            </g>
                            <defs>
                                <clipPath id="clip0_312_6">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </p>
                <h4>{{t('popular_food_items', ['homepage'])}}</h4>
            </div>
            <div class="container">
                <div class="swiper-container ">

                    <swiper :loop="true" :breakpoints="{
                      '640': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      '768': {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      '1024': {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      '1400': {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },

                      '2000': {
                        slidesPerView: 4,
                        spaceBetween: 30,
                      },

                      '2500': {
                        slidesPerView: 5,
                        spaceBetween: 30,
                      },
                    }"
                    :modules="modules"
                    :navigation="true"
                    :pagination="true"
                    class="ourfoodSwiper"

                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                  >
                    <swiper-slide v-for="item in popular" :key="item.id" >
                        <div class="single-food-items">
                        <div class="item-thumb">
                            <img :src="getItemImage(item)" t="thumb" class="rounded-circle" width="165">
                            <div class="circle-shape"><img class="cir36" src="@/assets/homepage/circle.svg" alt="shape"></div>
                        </div>
                        <div class="item-content">
                            <router-link to="/ordernow">
                                <h3>{{t(item.trans_code, ['menu_item'])}}</h3>
                            </router-link>
                            <div class="text kcal-wrap-content d-flex align-items-center justify-content-center gap-2">
                              <span class="d-flex align-items-center gap-2">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)"/>
                                  <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)"/>
                                  <defs>
                                  <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                  <stop offset="0.314" stop-color="#FF9800"/>
                                  <stop offset="0.662" stop-color="#FF6D00"/>
                                  <stop offset="0.972" stop-color="#F44336"/>
                                  </radialGradient>
                                  <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                  <stop offset="0.214" stop-color="#FFF176"/>
                                  <stop offset="0.328" stop-color="#FFF27D"/>
                                  <stop offset="0.487" stop-color="#FFF48F"/>
                                  <stop offset="0.672" stop-color="#FFF7AD"/>
                                  <stop offset="0.793" stop-color="#FFF9C4"/>
                                  <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804"/>
                                  <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529"/>
                                  <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209"/>
                                  <stop offset="0.941" stop-color="#FFF176" stop-opacity="0"/>
                                  </radialGradient>
                                  </defs>
                                  </svg>
                                  <span>{{ item.calories }}</span>
                              </span>
                              <span class="d-flex align-items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="m58.95 15.675-3.169 5.593-22.342-1.02 2.86-5.956c5.31.323 17.464 1.064 22.651 1.383zM44.7 29.914l-3.169 5.604-22.342-1.032 2.861-5.944zM30.451 44.164l-3.169 5.604L4.95 48.736l2.85-5.955zM58.748 13.527l-7.614-.458c-.022.02-1.436-2.956-1.446-2.956l4.743-3.658zM44.52 27.776l-7.71-.468-3.042-4.913 7.668.351zM30.28 42.026l-7.699-.468-3.062-4.923 7.678.35c.02-.019 3.072 5.042 3.083 5.041zM14.712 54.096 9.48 57.605l-4.211-6.72 7.688.35c.021-.02 1.744 2.862 1.755 2.86z" fill="#000000" opacity="1" data-original="#000000" class=""></path></g></svg>
                                  <span>{{ item.protein }}</span>
                              </span>
                              <span class="d-flex align-items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path fill-rule="evenodd" d="M385.231 137.188h28.236a7 7 0 1 0 0-14h-26.909c3.914-37.4 9.885-63.971 18.344-81.553C411.506 27.91 418.966 16 429.46 16s17.955 11.91 24.558 25.635c4.772 9.918 8.752 22.7 12.018 38.67h-28.458a7 7 0 1 0 0 14h31.007c3.194 19.725 5.534 43.494 7.124 71.767h-38.131a7 7 0 0 0 0 14h38.841q.7 15.3 1.137 32.227a78.007 78.007 0 0 0-48.464 42.138 78.014 78.014 0 0 0-47.734-41.916c.745-28.57 2.013-53.581 3.873-75.332zM97.707 421.3H227.2a146.133 146.133 0 0 0-17.248 44.7H97.707a11.609 11.609 0 0 1-11.587-11.587v-21.526A11.608 11.608 0 0 1 97.707 421.3zM492.5 322.636V223.5a63.646 63.646 0 0 0-56.4 63.015v87.114a148 148 0 0 1 12.444 9.335 63.675 63.675 0 0 0 43.959-60.33zm-126.806 0a62.781 62.781 0 0 0 6.153 27.152 146.486 146.486 0 0 1 50.25 15.545v-78.816a63.646 63.646 0 0 0-56.4-63.015v99.134zm-56.668 33.158a147.749 147.749 0 0 0-72.444 51.506H97.707a25.516 25.516 0 0 0-18.568 8 150.906 150.906 0 0 1-59.347-110.73 8.973 8.973 0 0 1 8.983-9.561h283.693a8.973 8.973 0 0 1 8.983 9.561 150.234 150.234 0 0 1-12.43 51.222zm-67.54-125.569c9.063-3.6 16.8-3.73 21.007.479s4.083 11.944.479 21.007c20.927-2.452 27.78 9.417 15.193 26.315a35.971 35.971 0 0 1 10.439 2.985H52.643a35.976 35.976 0 0 1 10.441-2.985c-12.587-16.9-5.736-28.768 15.193-26.315-3.6-9.062-3.73-16.8.479-21.008s11.944-4.082 21.007-.478c-1.134-9.686.744-17.192 5.9-20.168s12.6-.852 20.416 4.975c1.411-9.65 5.169-16.414 10.918-17.954s12.386 2.437 18.432 10.089c3.861-8.956 9.242-14.516 15.194-14.516s11.332 5.56 15.193 14.516c6.047-7.651 12.683-11.63 18.432-10.089s9.508 8.3 10.918 17.954c7.822-5.826 15.261-7.951 20.416-4.975s7.034 10.481 5.9 20.168zm27.678-158.833a31.526 31.526 0 0 0 40.218 18.239A2.98 2.98 0 0 0 
                                  311.3 86.3a31.458 31.458 0 0 0-32.731-26.051 172.187 172.187 0 0 0-26.22-9.387 31.382 31.382 0 0 0 15.99-22.512 2.978 2.978 0 0 0-1.948-3.313A31.516 31.516 0 0 0 225.562 45.9a170.863 170.863 0 0 0-51.6 2.517 31.733 31.733 0 0 0 4.43-30.182 2.979 2.979 0 0 0-3.344-1.895 31.45 31.45 0 0 0-24.881 38.683 170.643 170.643 0 0 0-53.952 31.256 31.45 31.45 0 0 0-12.584-29.633 2.978 2.978 0 0 0-3.835.24 31.518 31.518 0 0 0-.1 45.616 172.7 172.7 0 0 0-30.4 48.406 31.452 31.452 0 0 0-24.636-20.793 2.98 2.98 0 0 0-3.313 1.948A31.519 31.519 0 0 0 41.864 172.8a170.728 170.728 0 0 0-5.369 32.536 6.988 6.988 0 1 0 13.945.93 157.18 157.18 0 0 1 4.947-29.966 31.475 31.475 0 0 0 37.624-25.646 2.98 2.98 0 0 0-1.949-3.313 31.382 31.382 0 0 0-25.833 2.076 158.449 158.449 0 0 1 24.928-37.689 31.518 31.518 0 0 0 45.159-5.864 2.979 2.979 0 0 0-.24-3.836 31.388 31.388 0 0 0-24.058-9.828 156.864 156.864 0 0 1 42.933-23.684A31.52 31.52 0 0 0 194.779 87.7a2.979 2.979 0 0 0 1.9-3.344 31.4 31.4 0 0 0-17.319-22.741 157.061 157.061 0 0 1 42.691-2A31.473 31.473 0 0 0 247.807 96.7a2.98 2.98 0 0 0 3.313-1.948 31.636 31.636 0 0 0-5.883-31.283 157.966 157.966 0 0 1 23.927 7.921zm91.991 291.523v84.854a7 7 0 0 1-14 0v-84.854a132.9 132.9 0 0 0-30.995 5.31v79.544a7 7 0 0 1-14 0v-74.508a133.312 133.312 0 0 0-31 18.467v56.041a7 7 0 1 1-14 0v-43.16a132.757 132.757 0 0 0-35.937 81.749 8.969 8.969 0 0 0 8.985 9.642H478.1a8.968 8.968 0 0 0 8.979-9.642 132.771 132.771 0 0 0-35.937-81.749v43.16a7 7 0 1 1-14 0v-56.041a133.208 133.208 0 0 0-31-18.467v74.508a7 7 0 0 1-14 0v-79.544a132.9 132.9 0 0 0-30.987-5.31z" fill="#000000" opacity="1" data-original="#000000" class=""></path></g></svg>
                                  <span>{{ item.carbs }}</span>
                              </span>
                              <span class="d-flex align-items-center gap-2">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)"/>
                                  <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)"/>
                                  <defs>
                                  <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                  <stop offset="0.314" stop-color="#FF9800"/>
                                  <stop offset="0.662" stop-color="#FF6D00"/>
                                  <stop offset="0.972" stop-color="#F44336"/>
                                  </radialGradient>
                                  <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                  <stop offset="0.214" stop-color="#FFF176"/>
                                  <stop offset="0.328" stop-color="#FFF27D"/>
                                  <stop offset="0.487" stop-color="#FFF48F"/>
                                  <stop offset="0.672" stop-color="#FFF7AD"/>
                                  <stop offset="0.793" stop-color="#FFF9C4"/>
                                  <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804"/>
                                  <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529"/>
                                  <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209"/>
                                  <stop offset="0.941" stop-color="#FFF176" stop-opacity="0"/>
                                  </radialGradient>
                                  </defs>
                                  </svg>
                                 <span>{{ item.fats }}</span>
                              </span>
                            </div>
                            <h6>{{ item.price }}</h6>
                        </div>
                      </div>
                    </swiper-slide>
 
                    <!-- <swiper-slide>
                        <div class="single-food-items">
                        <div class="item-thumb">
                            <img src="@/assets/homepage/dish-2.png" alt="thumb">
                            <div class="circle-shape"><img class="cir36" src="@/assets/homepage/circle.svg" alt="shape"></div>
                        </div>
                        <div class="item-content">
                            <a href="menu.html">
                                <h3>Chicken-Stuffed Bean Curd Rolls</h3>
                            </a>
                            <div class="text kcal-wrap-content d-flex align-items-center justify-content-center gap-2">
                              <span class="d-flex align-items-center gap-2">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)"/>
                                  <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)"/>
                                  <defs>
                                  <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                  <stop offset="0.314" stop-color="#FF9800"/>
                                  <stop offset="0.662" stop-color="#FF6D00"/>
                                  <stop offset="0.972" stop-color="#F44336"/>
                                  </radialGradient>
                                  <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                  <stop offset="0.214" stop-color="#FFF176"/>
                                  <stop offset="0.328" stop-color="#FFF27D"/>
                                  <stop offset="0.487" stop-color="#FFF48F"/>
                                  <stop offset="0.672" stop-color="#FFF7AD"/>
                                  <stop offset="0.793" stop-color="#FFF9C4"/>
                                  <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804"/>
                                  <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529"/>
                                  <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209"/>
                                  <stop offset="0.941" stop-color="#FFF176" stop-opacity="0"/>
                                  </radialGradient>
                                  </defs>
                                  </svg>
                                  <span>Calories</span>
                              </span>
                              <span class="d-flex align-items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="m58.95 15.675-3.169 5.593-22.342-1.02 2.86-5.956c5.31.323 17.464 1.064 22.651 1.383zM44.7 29.914l-3.169 5.604-22.342-1.032 2.861-5.944zM30.451 44.164l-3.169 5.604L4.95 48.736l2.85-5.955zM58.748 13.527l-7.614-.458c-.022.02-1.436-2.956-1.446-2.956l4.743-3.658zM44.52 27.776l-7.71-.468-3.042-4.913 7.668.351zM30.28 42.026l-7.699-.468-3.062-4.923 7.678.35c.02-.019 3.072 5.042 3.083 5.041zM14.712 54.096 9.48 57.605l-4.211-6.72 7.688.35c.021-.02 1.744 2.862 1.755 2.86z" fill="#000000" opacity="1" data-original="#000000" class=""></path></g></svg>
                                  <span>Protein</span>
                              </span>
                              <span class="d-flex align-items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path fill-rule="evenodd" d="M385.231 137.188h28.236a7 7 0 1 0 0-14h-26.909c3.914-37.4 9.885-63.971 18.344-81.553C411.506 27.91 418.966 16 429.46 16s17.955 11.91 24.558 25.635c4.772 9.918 8.752 22.7 12.018 38.67h-28.458a7 7 0 1 0 0 14h31.007c3.194 19.725 5.534 43.494 7.124 71.767h-38.131a7 7 0 0 0 0 14h38.841q.7 15.3 1.137 32.227a78.007 78.007 0 0 0-48.464 42.138 78.014 78.014 0 0 0-47.734-41.916c.745-28.57 2.013-53.581 3.873-75.332zM97.707 421.3H227.2a146.133 146.133 0 0 0-17.248 44.7H97.707a11.609 11.609 0 0 1-11.587-11.587v-21.526A11.608 11.608 0 0 1 97.707 421.3zM492.5 322.636V223.5a63.646 63.646 0 0 0-56.4 63.015v87.114a148 148 0 0 1 12.444 9.335 63.675 63.675 0 0 0 43.959-60.33zm-126.806 0a62.781 62.781 0 0 0 6.153 27.152 146.486 146.486 0 0 1 50.25 15.545v-78.816a63.646 63.646 0 0 0-56.4-63.015v99.134zm-56.668 33.158a147.749 147.749 0 0 0-72.444 51.506H97.707a25.516 25.516 0 0 0-18.568 8 150.906 150.906 0 0 1-59.347-110.73 8.973 8.973 0 0 1 8.983-9.561h283.693a8.973 8.973 0 0 1 8.983 9.561 150.234 150.234 0 0 1-12.43 51.222zm-67.54-125.569c9.063-3.6 16.8-3.73 21.007.479s4.083 11.944.479 21.007c20.927-2.452 27.78 9.417 15.193 26.315a35.971 35.971 0 0 1 10.439 2.985H52.643a35.976 35.976 0 0 1 10.441-2.985c-12.587-16.9-5.736-28.768 15.193-26.315-3.6-9.062-3.73-16.8.479-21.008s11.944-4.082 21.007-.478c-1.134-9.686.744-17.192 5.9-20.168s12.6-.852 20.416 4.975c1.411-9.65 5.169-16.414 10.918-17.954s12.386 2.437 18.432 10.089c3.861-8.956 9.242-14.516 15.194-14.516s11.332 5.56 15.193 14.516c6.047-7.651 12.683-11.63 18.432-10.089s9.508 8.3 10.918 17.954c7.822-5.826 15.261-7.951 20.416-4.975s7.034 10.481 5.9 20.168zm27.678-158.833a31.526 31.526 0 0 0 40.218 18.239A2.98 2.98 0 0 0 
                                  311.3 86.3a31.458 31.458 0 0 0-32.731-26.051 172.187 172.187 0 0 0-26.22-9.387 31.382 31.382 0 0 0 15.99-22.512 2.978 2.978 0 0 0-1.948-3.313A31.516 31.516 0 0 0 225.562 45.9a170.863 170.863 0 0 0-51.6 2.517 31.733 31.733 0 0 0 4.43-30.182 2.979 2.979 0 0 0-3.344-1.895 31.45 31.45 0 0 0-24.881 38.683 170.643 170.643 0 0 0-53.952 31.256 31.45 31.45 0 0 0-12.584-29.633 2.978 2.978 0 0 0-3.835.24 31.518 31.518 0 0 0-.1 45.616 172.7 172.7 0 0 0-30.4 48.406 31.452 31.452 0 0 0-24.636-20.793 2.98 2.98 0 0 0-3.313 1.948A31.519 31.519 0 0 0 41.864 172.8a170.728 170.728 0 0 0-5.369 32.536 6.988 6.988 0 1 0 13.945.93 157.18 157.18 0 0 1 4.947-29.966 31.475 31.475 0 0 0 37.624-25.646 2.98 2.98 0 0 0-1.949-3.313 31.382 31.382 0 0 0-25.833 2.076 158.449 158.449 0 0 1 24.928-37.689 31.518 31.518 0 0 0 45.159-5.864 2.979 2.979 0 0 0-.24-3.836 31.388 31.388 0 0 0-24.058-9.828 156.864 156.864 0 0 1 42.933-23.684A31.52 31.52 0 0 0 194.779 87.7a2.979 2.979 0 0 0 1.9-3.344 31.4 31.4 0 0 0-17.319-22.741 157.061 157.061 0 0 1 42.691-2A31.473 31.473 0 0 0 247.807 96.7a2.98 2.98 0 0 0 3.313-1.948 31.636 31.636 0 0 0-5.883-31.283 157.966 157.966 0 0 1 23.927 7.921zm91.991 291.523v84.854a7 7 0 0 1-14 0v-84.854a132.9 132.9 0 0 0-30.995 5.31v79.544a7 7 0 0 1-14 0v-74.508a133.312 133.312 0 0 0-31 18.467v56.041a7 7 0 1 1-14 0v-43.16a132.757 132.757 0 0 0-35.937 81.749 8.969 8.969 0 0 0 8.985 9.642H478.1a8.968 8.968 0 0 0 8.979-9.642 132.771 132.771 0 0 0-35.937-81.749v43.16a7 7 0 1 1-14 0v-56.041a133.208 133.208 0 0 0-31-18.467v74.508a7 7 0 0 1-14 0v-79.544a132.9 132.9 0 0 0-30.987-5.31z" fill="#000000" opacity="1" data-original="#000000" class=""></path></g></svg>
                                  <span>carbs</span>
                              </span>
                              <span class="d-flex align-items-center gap-2">
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)"/>
                                  <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)"/>
                                  <defs>
                                  <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                  <stop offset="0.314" stop-color="#FF9800"/>
                                  <stop offset="0.662" stop-color="#FF6D00"/>
                                  <stop offset="0.972" stop-color="#F44336"/>
                                  </radialGradient>
                                  <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                  <stop offset="0.214" stop-color="#FFF176"/>
                                  <stop offset="0.328" stop-color="#FFF27D"/>
                                  <stop offset="0.487" stop-color="#FFF48F"/>
                                  <stop offset="0.672" stop-color="#FFF7AD"/>
                                  <stop offset="0.793" stop-color="#FFF9C4"/>
                                  <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804"/>
                                  <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529"/>
                                  <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209"/>
                                  <stop offset="0.941" stop-color="#FFF176" stop-opacity="0"/>
                                  </radialGradient>
                                  </defs>
                                  </svg>
                                 <span>Fats</span>
                              </span>
                            </div>
                            <h6>$20.99</h6>
                        </div>
                      </div>
                    </swiper-slide>

                        <swiper-slide>
                            <div class="single-food-items">
                                <div class="item-thumb">
                                    <img src="@/assets/homepage/dish-2.png" alt="thumb">
                                    <div class="circle-shape"><img class="cir36" src="@/assets/homepage/circle.svg" alt="shape"></div>
                                </div>
                                <div class="item-content">
                                    <a href="menu.html">
                                        <h3>Chicken-Stuffed Bean Curd Rolls</h3>
                                    </a>
                                    <div class="text kcal-wrap-content d-flex align-items-center justify-content-center gap-2">
                                        <span class="d-flex align-items-center gap-2">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)" />
                                                <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)" />
                                                <defs>
                                                    <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                                        <stop offset="0.314" stop-color="#FF9800" />
                                                        <stop offset="0.662" stop-color="#FF6D00" />
                                                        <stop offset="0.972" stop-color="#F44336" />
                                                    </radialGradient>
                                                    <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                                        <stop offset="0.214" stop-color="#FFF176" />
                                                        <stop offset="0.328" stop-color="#FFF27D" />
                                                        <stop offset="0.487" stop-color="#FFF48F" />
                                                        <stop offset="0.672" stop-color="#FFF7AD" />
                                                        <stop offset="0.793" stop-color="#FFF9C4" />
                                                        <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804" />
                                                        <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529" />
                                                        <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209" />
                                                        <stop offset="0.941" stop-color="#FFF176" stop-opacity="0" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                            <span>Calories</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                                                <g>
                                                    <path d="m58.95 15.675-3.169 5.593-22.342-1.02 2.86-5.956c5.31.323 17.464 1.064 22.651 1.383zM44.7 29.914l-3.169 5.604-22.342-1.032 2.861-5.944zM30.451 44.164l-3.169 5.604L4.95 48.736l2.85-5.955zM58.748 13.527l-7.614-.458c-.022.02-1.436-2.956-1.446-2.956l4.743-3.658zM44.52 27.776l-7.71-.468-3.042-4.913 7.668.351zM30.28 42.026l-7.699-.468-3.062-4.923 7.678.35c.02-.019 3.072 5.042 3.083 5.041zM14.712 54.096 9.48 57.605l-4.211-6.72 7.688.35c.021-.02 1.744 2.862 1.755 2.86z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                </g>
                                            </svg>
                                            <span>Protein</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                                                <g>
                                                    <path fill-rule="evenodd" d="M385.231 137.188h28.236a7 7 0 1 0 0-14h-26.909c3.914-37.4 9.885-63.971 18.344-81.553C411.506 27.91 418.966 16 429.46 16s17.955 11.91 24.558 25.635c4.772 9.918 8.752 22.7 12.018 38.67h-28.458a7 7 0 1 0 0 14h31.007c3.194 19.725 5.534 43.494 7.124 71.767h-38.131a7 7 0 0 0 0 14h38.841q.7 15.3 1.137 32.227a78.007 78.007 0 0 0-48.464 42.138 78.014 78.014 0 0 0-47.734-41.916c.745-28.57 2.013-53.581 3.873-75.332zM97.707 421.3H227.2a146.133 146.133 0 0 0-17.248 44.7H97.707a11.609 11.609 0 0 1-11.587-11.587v-21.526A11.608 11.608 0 0 1 97.707 421.3zM492.5 322.636V223.5a63.646 63.646 0 0 0-56.4 63.015v87.114a148 148 0 0 1 12.444 9.335 63.675 63.675 0 0 0 43.959-60.33zm-126.806 0a62.781 62.781 0 0 0 6.153 27.152 146.486 146.486 0 0 1 50.25 15.545v-78.816a63.646 63.646 0 0 0-56.4-63.015v99.134zm-56.668 33.158a147.749 147.749 0 0 0-72.444 51.506H97.707a25.516 25.516 0 0 0-18.568 8 150.906 150.906 0 0 1-59.347-110.73 8.973 8.973 0 0 1 8.983-9.561h283.693a8.973 8.973 0 0 1 8.983 9.561 150.234 150.234 0 0 1-12.43 51.222zm-67.54-125.569c9.063-3.6 16.8-3.73 21.007.479s4.083 11.944.479 21.007c20.927-2.452 27.78 9.417 15.193 26.315a35.971 35.971 0 0 1 10.439 2.985H52.643a35.976 35.976 0 0 1 10.441-2.985c-12.587-16.9-5.736-28.768 15.193-26.315-3.6-9.062-3.73-16.8.479-21.008s11.944-4.082 21.007-.478c-1.134-9.686.744-17.192 5.9-20.168s12.6-.852 20.416 4.975c1.411-9.65 5.169-16.414 10.918-17.954s12.386 2.437 18.432 10.089c3.861-8.956 9.242-14.516 15.194-14.516s11.332 5.56 15.193 14.516c6.047-7.651 12.683-11.63 18.432-10.089s9.508 8.3 10.918 17.954c7.822-5.826 15.261-7.951 20.416-4.975s7.034 10.481 5.9 20.168zm27.678-158.833a31.526 31.526 0 0 0 40.218 18.239A2.98 2.98 0 0 0 
                                  311.3 86.3a31.458 31.458 0 0 0-32.731-26.051 172.187 172.187 0 0 0-26.22-9.387 31.382 31.382 0 0 0 15.99-22.512 2.978 2.978 0 0 0-1.948-3.313A31.516 31.516 0 0 0 225.562 45.9a170.863 170.863 0 0 0-51.6 2.517 31.733 31.733 0 0 0 4.43-30.182 2.979 2.979 0 0 0-3.344-1.895 31.45 31.45 0 0 0-24.881 38.683 170.643 170.643 0 0 0-53.952 31.256 31.45 31.45 0 0 0-12.584-29.633 2.978 2.978 0 0 0-3.835.24 31.518 31.518 0 0 0-.1 45.616 172.7 172.7 0 0 0-30.4 48.406 31.452 31.452 0 0 0-24.636-20.793 2.98 2.98 0 0 0-3.313 1.948A31.519 31.519 0 0 0 41.864 172.8a170.728 170.728 0 0 0-5.369 32.536 6.988 6.988 0 1 0 13.945.93 157.18 157.18 0 0 1 4.947-29.966 31.475 31.475 0 0 0 37.624-25.646 2.98 2.98 0 0 0-1.949-3.313 31.382 31.382 0 0 0-25.833 2.076 158.449 158.449 0 0 1 24.928-37.689 31.518 31.518 0 0 0 45.159-5.864 2.979 2.979 0 0 0-.24-3.836 31.388 31.388 0 0 0-24.058-9.828 156.864 156.864 0 0 1 42.933-23.684A31.52 31.52 0 0 0 194.779 87.7a2.979 2.979 0 0 0 1.9-3.344 31.4 31.4 0 0 0-17.319-22.741 157.061 157.061 0 0 1 42.691-2A31.473 31.473 0 0 0 247.807 96.7a2.98 2.98 0 0 0 3.313-1.948 31.636 31.636 0 0 0-5.883-31.283 157.966 157.966 0 0 1 23.927 7.921zm91.991 291.523v84.854a7 7 0 0 1-14 0v-84.854a132.9 132.9 0 0 0-30.995 5.31v79.544a7 7 0 0 1-14 0v-74.508a133.312 133.312 0 0 0-31 18.467v56.041a7 7 0 1 1-14 0v-43.16a132.757 132.757 0 0 0-35.937 81.749 8.969 8.969 0 0 0 8.985 9.642H478.1a8.968 8.968 0 0 0 8.979-9.642 132.771 132.771 0 0 0-35.937-81.749v43.16a7 7 0 1 1-14 0v-56.041a133.208 133.208 0 0 0-31-18.467v74.508a7 7 0 0 1-14 0v-79.544a132.9 132.9 0 0 0-30.987-5.31z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                </g>
                                            </svg>
                                            <span>carbs</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)" />
                                                <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)" />
                                                <defs>
                                                    <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                                        <stop offset="0.314" stop-color="#FF9800" />
                                                        <stop offset="0.662" stop-color="#FF6D00" />
                                                        <stop offset="0.972" stop-color="#F44336" />
                                                    </radialGradient>
                                                    <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                                        <stop offset="0.214" stop-color="#FFF176" />
                                                        <stop offset="0.328" stop-color="#FFF27D" />
                                                        <stop offset="0.487" stop-color="#FFF48F" />
                                                        <stop offset="0.672" stop-color="#FFF7AD" />
                                                        <stop offset="0.793" stop-color="#FFF9C4" />
                                                        <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804" />
                                                        <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529" />
                                                        <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209" />
                                                        <stop offset="0.941" stop-color="#FFF176" stop-opacity="0" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                            <span>Fats</span>
                                        </span>
                                    </div>
                                    <h6>$20.99</h6>
                                </div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="single-food-items">
                                <div class="item-thumb">
                                    <img src="@/assets/homepage/dish-2.png" alt="thumb">
                                    <div class="circle-shape"><img class="cir36" src="@/assets/homepage/circle.svg" alt="shape"></div>
                                </div>
                                <div class="item-content">
                                    <a href="menu.html">
                                        <h3>Chicken-Stuffed Bean Curd Rolls</h3>
                                    </a>
                                    <div class="text kcal-wrap-content d-flex align-items-center justify-content-center gap-2">
                                        <span class="d-flex align-items-center gap-2">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)" />
                                                <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)" />
                                                <defs>
                                                    <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                                        <stop offset="0.314" stop-color="#FF9800" />
                                                        <stop offset="0.662" stop-color="#FF6D00" />
                                                        <stop offset="0.972" stop-color="#F44336" />
                                                    </radialGradient>
                                                    <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                                        <stop offset="0.214" stop-color="#FFF176" />
                                                        <stop offset="0.328" stop-color="#FFF27D" />
                                                        <stop offset="0.487" stop-color="#FFF48F" />
                                                        <stop offset="0.672" stop-color="#FFF7AD" />
                                                        <stop offset="0.793" stop-color="#FFF9C4" />
                                                        <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804" />
                                                        <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529" />
                                                        <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209" />
                                                        <stop offset="0.941" stop-color="#FFF176" stop-opacity="0" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                            <span>Calories</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                                                <g>
                                                    <path d="m58.95 15.675-3.169 5.593-22.342-1.02 2.86-5.956c5.31.323 17.464 1.064 22.651 1.383zM44.7 29.914l-3.169 5.604-22.342-1.032 2.861-5.944zM30.451 44.164l-3.169 5.604L4.95 48.736l2.85-5.955zM58.748 13.527l-7.614-.458c-.022.02-1.436-2.956-1.446-2.956l4.743-3.658zM44.52 27.776l-7.71-.468-3.042-4.913 7.668.351zM30.28 42.026l-7.699-.468-3.062-4.923 7.678.35c.02-.019 3.072 5.042 3.083 5.041zM14.712 54.096 9.48 57.605l-4.211-6.72 7.688.35c.021-.02 1.744 2.862 1.755 2.86z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                </g>
                                            </svg>
                                            <span>Protein</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                                                <g>
                                                    <path fill-rule="evenodd" d="M385.231 137.188h28.236a7 7 0 1 0 0-14h-26.909c3.914-37.4 9.885-63.971 18.344-81.553C411.506 27.91 418.966 16 429.46 16s17.955 11.91 24.558 25.635c4.772 9.918 8.752 22.7 12.018 38.67h-28.458a7 7 0 1 0 0 14h31.007c3.194 19.725 5.534 43.494 7.124 71.767h-38.131a7 7 0 0 0 0 14h38.841q.7 15.3 1.137 32.227a78.007 78.007 0 0 0-48.464 42.138 78.014 78.014 0 0 0-47.734-41.916c.745-28.57 2.013-53.581 3.873-75.332zM97.707 421.3H227.2a146.133 146.133 0 0 0-17.248 44.7H97.707a11.609 11.609 0 0 1-11.587-11.587v-21.526A11.608 11.608 0 0 1 97.707 421.3zM492.5 322.636V223.5a63.646 63.646 0 0 0-56.4 63.015v87.114a148 148 0 0 1 12.444 9.335 63.675 63.675 0 0 0 43.959-60.33zm-126.806 0a62.781 62.781 0 0 0 6.153 27.152 146.486 146.486 0 0 1 50.25 15.545v-78.816a63.646 63.646 0 0 0-56.4-63.015v99.134zm-56.668 33.158a147.749 147.749 0 0 0-72.444 51.506H97.707a25.516 25.516 0 0 0-18.568 8 150.906 150.906 0 0 1-59.347-110.73 8.973 8.973 0 0 1 8.983-9.561h283.693a8.973 8.973 0 0 1 8.983 9.561 150.234 150.234 0 0 1-12.43 51.222zm-67.54-125.569c9.063-3.6 16.8-3.73 21.007.479s4.083 11.944.479 21.007c20.927-2.452 27.78 9.417 15.193 26.315a35.971 35.971 0 0 1 10.439 2.985H52.643a35.976 35.976 0 0 1 10.441-2.985c-12.587-16.9-5.736-28.768 15.193-26.315-3.6-9.062-3.73-16.8.479-21.008s11.944-4.082 21.007-.478c-1.134-9.686.744-17.192 5.9-20.168s12.6-.852 20.416 4.975c1.411-9.65 5.169-16.414 10.918-17.954s12.386 2.437 18.432 10.089c3.861-8.956 9.242-14.516 15.194-14.516s11.332 5.56 15.193 14.516c6.047-7.651 12.683-11.63 18.432-10.089s9.508 8.3 10.918 17.954c7.822-5.826 15.261-7.951 20.416-4.975s7.034 10.481 5.9 20.168zm27.678-158.833a31.526 31.526 0 0 0 40.218 18.239A2.98 2.98 0 0 0 
                                  311.3 86.3a31.458 31.458 0 0 0-32.731-26.051 172.187 172.187 0 0 0-26.22-9.387 31.382 31.382 0 0 0 15.99-22.512 2.978 2.978 0 0 0-1.948-3.313A31.516 31.516 0 0 0 225.562 45.9a170.863 170.863 0 0 0-51.6 2.517 31.733 31.733 0 0 0 4.43-30.182 2.979 2.979 0 0 0-3.344-1.895 31.45 31.45 0 0 0-24.881 38.683 170.643 170.643 0 0 0-53.952 31.256 31.45 31.45 0 0 0-12.584-29.633 2.978 2.978 0 0 0-3.835.24 31.518 31.518 0 0 0-.1 45.616 172.7 172.7 0 0 0-30.4 48.406 31.452 31.452 0 0 0-24.636-20.793 2.98 2.98 0 0 0-3.313 1.948A31.519 31.519 0 0 0 41.864 172.8a170.728 170.728 0 0 0-5.369 32.536 6.988 6.988 0 1 0 13.945.93 157.18 157.18 0 0 1 4.947-29.966 31.475 31.475 0 0 0 37.624-25.646 2.98 2.98 0 0 0-1.949-3.313 31.382 31.382 0 0 0-25.833 2.076 158.449 158.449 0 0 1 24.928-37.689 31.518 31.518 0 0 0 45.159-5.864 2.979 2.979 0 0 0-.24-3.836 31.388 31.388 0 0 0-24.058-9.828 156.864 156.864 0 0 1 42.933-23.684A31.52 31.52 0 0 0 194.779 87.7a2.979 2.979 0 0 0 1.9-3.344 31.4 31.4 0 0 0-17.319-22.741 157.061 157.061 0 0 1 42.691-2A31.473 31.473 0 0 0 247.807 96.7a2.98 2.98 0 0 0 3.313-1.948 31.636 31.636 0 0 0-5.883-31.283 157.966 157.966 0 0 1 23.927 7.921zm91.991 291.523v84.854a7 7 0 0 1-14 0v-84.854a132.9 132.9 0 0 0-30.995 5.31v79.544a7 7 0 0 1-14 0v-74.508a133.312 133.312 0 0 0-31 18.467v56.041a7 7 0 1 1-14 0v-43.16a132.757 132.757 0 0 0-35.937 81.749 8.969 8.969 0 0 0 8.985 9.642H478.1a8.968 8.968 0 0 0 8.979-9.642 132.771 132.771 0 0 0-35.937-81.749v43.16a7 7 0 1 1-14 0v-56.041a133.208 133.208 0 0 0-31-18.467v74.508a7 7 0 0 1-14 0v-79.544a132.9 132.9 0 0 0-30.987-5.31z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                </g>
                                            </svg>
                                            <span>carbs</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)" />
                                                <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)" />
                                                <defs>
                                                    <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                                        <stop offset="0.314" stop-color="#FF9800" />
                                                        <stop offset="0.662" stop-color="#FF6D00" />
                                                        <stop offset="0.972" stop-color="#F44336" />
                                                    </radialGradient>
                                                    <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                                        <stop offset="0.214" stop-color="#FFF176" />
                                                        <stop offset="0.328" stop-color="#FFF27D" />
                                                        <stop offset="0.487" stop-color="#FFF48F" />
                                                        <stop offset="0.672" stop-color="#FFF7AD" />
                                                        <stop offset="0.793" stop-color="#FFF9C4" />
                                                        <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804" />
                                                        <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529" />
                                                        <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209" />
                                                        <stop offset="0.941" stop-color="#FFF176" stop-opacity="0" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                            <span>Fats</span>
                                        </span>
                                    </div>
                                    <h6>$20.99</h6>
                                </div>
                            </div>
                        </swiper-slide> 
                        -->

                        <!-- <swiper-slide>
                            <div class="single-food-items">
                                <div class="item-thumb">
                                    <img src="@/assets/homepage/dish-2.png" alt="thumb">
                                    <div class="circle-shape"><img class="cir36" src="@/assets/homepage/circle.svg" alt="shape"></div>
                                </div>
                                <div class="item-content">
                                    <a href="menu.html">
                                        <h3>Chicken-Stuffed Bean Curd Rolls</h3>
                                    </a>
                                    <div class="text kcal-wrap-content d-flex align-items-center justify-content-center gap-2">
                                        <span class="d-flex align-items-center gap-2">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)" />
                                                <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)" />
                                                <defs>
                                                    <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                                        <stop offset="0.314" stop-color="#FF9800" />
                                                        <stop offset="0.662" stop-color="#FF6D00" />
                                                        <stop offset="0.972" stop-color="#F44336" />
                                                    </radialGradient>
                                                    <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                                        <stop offset="0.214" stop-color="#FFF176" />
                                                        <stop offset="0.328" stop-color="#FFF27D" />
                                                        <stop offset="0.487" stop-color="#FFF48F" />
                                                        <stop offset="0.672" stop-color="#FFF7AD" />
                                                        <stop offset="0.793" stop-color="#FFF9C4" />
                                                        <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804" />
                                                        <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529" />
                                                        <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209" />
                                                        <stop offset="0.941" stop-color="#FFF176" stop-opacity="0" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                            <span>Calories</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                                                <g>
                                                    <path d="m58.95 15.675-3.169 5.593-22.342-1.02 2.86-5.956c5.31.323 17.464 1.064 22.651 1.383zM44.7 29.914l-3.169 5.604-22.342-1.032 2.861-5.944zM30.451 44.164l-3.169 5.604L4.95 48.736l2.85-5.955zM58.748 13.527l-7.614-.458c-.022.02-1.436-2.956-1.446-2.956l4.743-3.658zM44.52 27.776l-7.71-.468-3.042-4.913 7.668.351zM30.28 42.026l-7.699-.468-3.062-4.923 7.678.35c.02-.019 3.072 5.042 3.083 5.041zM14.712 54.096 9.48 57.605l-4.211-6.72 7.688.35c.021-.02 1.744 2.862 1.755 2.86z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                </g>
                                            </svg>
                                            <span>Protein</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                                                <g>
                                                    <path fill-rule="evenodd" d="M385.231 137.188h28.236a7 7 0 1 0 0-14h-26.909c3.914-37.4 9.885-63.971 18.344-81.553C411.506 27.91 418.966 16 429.46 16s17.955 11.91 24.558 25.635c4.772 9.918 8.752 22.7 12.018 38.67h-28.458a7 7 0 1 0 0 14h31.007c3.194 19.725 5.534 43.494 7.124 71.767h-38.131a7 7 0 0 0 0 14h38.841q.7 15.3 1.137 32.227a78.007 78.007 0 0 0-48.464 42.138 78.014 78.014 0 0 0-47.734-41.916c.745-28.57 2.013-53.581 3.873-75.332zM97.707 421.3H227.2a146.133 146.133 0 0 0-17.248 44.7H97.707a11.609 11.609 0 0 1-11.587-11.587v-21.526A11.608 11.608 0 0 1 97.707 421.3zM492.5 322.636V223.5a63.646 63.646 0 0 0-56.4 63.015v87.114a148 148 0 0 1 12.444 9.335 63.675 63.675 0 0 0 43.959-60.33zm-126.806 0a62.781 62.781 0 0 0 6.153 27.152 146.486 146.486 0 0 1 50.25 15.545v-78.816a63.646 63.646 0 0 0-56.4-63.015v99.134zm-56.668 33.158a147.749 147.749 0 0 0-72.444 51.506H97.707a25.516 25.516 0 0 0-18.568 8 150.906 150.906 0 0 1-59.347-110.73 8.973 8.973 0 0 1 8.983-9.561h283.693a8.973 8.973 0 0 1 8.983 9.561 150.234 150.234 0 0 1-12.43 51.222zm-67.54-125.569c9.063-3.6 16.8-3.73 21.007.479s4.083 11.944.479 21.007c20.927-2.452 27.78 9.417 15.193 26.315a35.971 35.971 0 0 1 10.439 2.985H52.643a35.976 35.976 0 0 1 10.441-2.985c-12.587-16.9-5.736-28.768 15.193-26.315-3.6-9.062-3.73-16.8.479-21.008s11.944-4.082 21.007-.478c-1.134-9.686.744-17.192 5.9-20.168s12.6-.852 20.416 4.975c1.411-9.65 5.169-16.414 10.918-17.954s12.386 2.437 18.432 10.089c3.861-8.956 9.242-14.516 15.194-14.516s11.332 5.56 15.193 14.516c6.047-7.651 12.683-11.63 18.432-10.089s9.508 8.3 10.918 17.954c7.822-5.826 15.261-7.951 20.416-4.975s7.034 10.481 5.9 20.168zm27.678-158.833a31.526 31.526 0 0 0 40.218 18.239A2.98 2.98 0 0 0 
                                  311.3 86.3a31.458 31.458 0 0 0-32.731-26.051 172.187 172.187 0 0 0-26.22-9.387 31.382 31.382 0 0 0 15.99-22.512 2.978 2.978 0 0 0-1.948-3.313A31.516 31.516 0 0 0 225.562 45.9a170.863 170.863 0 0 0-51.6 2.517 31.733 31.733 0 0 0 4.43-30.182 2.979 2.979 0 0 0-3.344-1.895 31.45 31.45 0 0 0-24.881 38.683 170.643 170.643 0 0 0-53.952 31.256 31.45 31.45 0 0 0-12.584-29.633 2.978 2.978 0 0 0-3.835.24 31.518 31.518 0 0 0-.1 45.616 172.7 172.7 0 0 0-30.4 48.406 31.452 31.452 0 0 0-24.636-20.793 2.98 2.98 0 0 0-3.313 1.948A31.519 31.519 0 0 0 41.864 172.8a170.728 170.728 0 0 0-5.369 32.536 6.988 6.988 0 1 0 13.945.93 157.18 157.18 0 0 1 4.947-29.966 31.475 31.475 0 0 0 37.624-25.646 2.98 2.98 0 0 0-1.949-3.313 31.382 31.382 0 0 0-25.833 2.076 158.449 158.449 0 0 1 24.928-37.689 31.518 31.518 0 0 0 45.159-5.864 2.979 2.979 0 0 0-.24-3.836 31.388 31.388 0 0 0-24.058-9.828 156.864 156.864 0 0 1 42.933-23.684A31.52 31.52 0 0 0 194.779 87.7a2.979 2.979 0 0 0 1.9-3.344 31.4 31.4 0 0 0-17.319-22.741 157.061 157.061 0 0 1 42.691-2A31.473 31.473 0 0 0 247.807 96.7a2.98 2.98 0 0 0 3.313-1.948 31.636 31.636 0 0 0-5.883-31.283 157.966 157.966 0 0 1 23.927 7.921zm91.991 291.523v84.854a7 7 0 0 1-14 0v-84.854a132.9 132.9 0 0 0-30.995 5.31v79.544a7 7 0 0 1-14 0v-74.508a133.312 133.312 0 0 0-31 18.467v56.041a7 7 0 1 1-14 0v-43.16a132.757 132.757 0 0 0-35.937 81.749 8.969 8.969 0 0 0 8.985 9.642H478.1a8.968 8.968 0 0 0 8.979-9.642 132.771 132.771 0 0 0-35.937-81.749v43.16a7 7 0 1 1-14 0v-56.041a133.208 133.208 0 0 0-31-18.467v74.508a7 7 0 0 1-14 0v-79.544a132.9 132.9 0 0 0-30.987-5.31z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                </g>
                                            </svg>
                                            <span>carbs</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)" />
                                                <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)" />
                                                <defs>
                                                    <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                                        <stop offset="0.314" stop-color="#FF9800" />
                                                        <stop offset="0.662" stop-color="#FF6D00" />
                                                        <stop offset="0.972" stop-color="#F44336" />
                                                    </radialGradient>
                                                    <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                                        <stop offset="0.214" stop-color="#FFF176" />
                                                        <stop offset="0.328" stop-color="#FFF27D" />
                                                        <stop offset="0.487" stop-color="#FFF48F" />
                                                        <stop offset="0.672" stop-color="#FFF7AD" />
                                                        <stop offset="0.793" stop-color="#FFF9C4" />
                                                        <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804" />
                                                        <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529" />
                                                        <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209" />
                                                        <stop offset="0.941" stop-color="#FFF176" stop-opacity="0" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                            <span>Fats</span>
                                        </span>
                                    </div>
                                    <h6>$20.99</h6>
                                </div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="single-food-items">
                                <div class="item-thumb">
                                    <img src="@/assets/homepage/dish-2.png" alt="thumb">
                                    <div class="circle-shape"><img class="cir36" src="@/assets/homepage/circle.svg" alt="shape"></div>
                                </div>
                                <div class="item-content">
                                    <a href="menu.html">
                                        <h3>Chicken-Stuffed Bean Curd Rolls</h3>
                                    </a>
                                    <div class="text kcal-wrap-content d-flex align-items-center justify-content-center gap-2">
                                        <span class="d-flex align-items-center gap-2">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)" />
                                                <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)" />
                                                <defs>
                                                    <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                                        <stop offset="0.314" stop-color="#FF9800" />
                                                        <stop offset="0.662" stop-color="#FF6D00" />
                                                        <stop offset="0.972" stop-color="#F44336" />
                                                    </radialGradient>
                                                    <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                                        <stop offset="0.214" stop-color="#FFF176" />
                                                        <stop offset="0.328" stop-color="#FFF27D" />
                                                        <stop offset="0.487" stop-color="#FFF48F" />
                                                        <stop offset="0.672" stop-color="#FFF7AD" />
                                                        <stop offset="0.793" stop-color="#FFF9C4" />
                                                        <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804" />
                                                        <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529" />
                                                        <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209" />
                                                        <stop offset="0.941" stop-color="#FFF176" stop-opacity="0" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                            <span>Calories</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                                                <g>
                                                    <path d="m58.95 15.675-3.169 5.593-22.342-1.02 2.86-5.956c5.31.323 17.464 1.064 22.651 1.383zM44.7 29.914l-3.169 5.604-22.342-1.032 2.861-5.944zM30.451 44.164l-3.169 5.604L4.95 48.736l2.85-5.955zM58.748 13.527l-7.614-.458c-.022.02-1.436-2.956-1.446-2.956l4.743-3.658zM44.52 27.776l-7.71-.468-3.042-4.913 7.668.351zM30.28 42.026l-7.699-.468-3.062-4.923 7.678.35c.02-.019 3.072 5.042 3.083 5.041zM14.712 54.096 9.48 57.605l-4.211-6.72 7.688.35c.021-.02 1.744 2.862 1.755 2.86z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                </g>
                                            </svg>
                                            <span>Protein</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                                                <g>
                                                    <path fill-rule="evenodd" d="M385.231 137.188h28.236a7 7 0 1 0 0-14h-26.909c3.914-37.4 9.885-63.971 18.344-81.553C411.506 27.91 418.966 16 429.46 16s17.955 11.91 24.558 25.635c4.772 9.918 8.752 22.7 12.018 38.67h-28.458a7 7 0 1 0 0 14h31.007c3.194 19.725 5.534 43.494 7.124 71.767h-38.131a7 7 0 0 0 0 14h38.841q.7 15.3 1.137 32.227a78.007 78.007 0 0 0-48.464 42.138 78.014 78.014 0 0 0-47.734-41.916c.745-28.57 2.013-53.581 3.873-75.332zM97.707 421.3H227.2a146.133 146.133 0 0 0-17.248 44.7H97.707a11.609 11.609 0 0 1-11.587-11.587v-21.526A11.608 11.608 0 0 1 97.707 421.3zM492.5 322.636V223.5a63.646 63.646 0 0 0-56.4 63.015v87.114a148 148 0 0 1 12.444 9.335 63.675 63.675 0 0 0 43.959-60.33zm-126.806 0a62.781 62.781 0 0 0 6.153 27.152 146.486 146.486 0 0 1 50.25 15.545v-78.816a63.646 63.646 0 0 0-56.4-63.015v99.134zm-56.668 33.158a147.749 147.749 0 0 0-72.444 51.506H97.707a25.516 25.516 0 0 0-18.568 8 150.906 150.906 0 0 1-59.347-110.73 8.973 8.973 0 0 1 8.983-9.561h283.693a8.973 8.973 0 0 1 8.983 9.561 150.234 150.234 0 0 1-12.43 51.222zm-67.54-125.569c9.063-3.6 16.8-3.73 21.007.479s4.083 11.944.479 21.007c20.927-2.452 27.78 9.417 15.193 26.315a35.971 35.971 0 0 1 10.439 2.985H52.643a35.976 35.976 0 0 1 10.441-2.985c-12.587-16.9-5.736-28.768 15.193-26.315-3.6-9.062-3.73-16.8.479-21.008s11.944-4.082 21.007-.478c-1.134-9.686.744-17.192 5.9-20.168s12.6-.852 20.416 4.975c1.411-9.65 5.169-16.414 10.918-17.954s12.386 2.437 18.432 10.089c3.861-8.956 9.242-14.516 15.194-14.516s11.332 5.56 15.193 14.516c6.047-7.651 12.683-11.63 18.432-10.089s9.508 8.3 10.918 17.954c7.822-5.826 15.261-7.951 20.416-4.975s7.034 10.481 5.9 20.168zm27.678-158.833a31.526 31.526 0 0 0 40.218 18.239A2.98 2.98 0 0 0 
                                  311.3 86.3a31.458 31.458 0 0 0-32.731-26.051 172.187 172.187 0 0 0-26.22-9.387 31.382 31.382 0 0 0 15.99-22.512 2.978 2.978 0 0 0-1.948-3.313A31.516 31.516 0 0 0 225.562 45.9a170.863 170.863 0 0 0-51.6 2.517 31.733 31.733 0 0 0 4.43-30.182 2.979 2.979 0 0 0-3.344-1.895 31.45 31.45 0 0 0-24.881 38.683 170.643 170.643 0 0 0-53.952 31.256 31.45 31.45 0 0 0-12.584-29.633 2.978 2.978 0 0 0-3.835.24 31.518 31.518 0 0 0-.1 45.616 172.7 172.7 0 0 0-30.4 48.406 31.452 31.452 0 0 0-24.636-20.793 2.98 2.98 0 0 0-3.313 1.948A31.519 31.519 0 0 0 41.864 172.8a170.728 170.728 0 0 0-5.369 32.536 6.988 6.988 0 1 0 13.945.93 157.18 157.18 0 0 1 4.947-29.966 31.475 31.475 0 0 0 37.624-25.646 2.98 2.98 0 0 0-1.949-3.313 31.382 31.382 0 0 0-25.833 2.076 158.449 158.449 0 0 1 24.928-37.689 31.518 31.518 0 0 0 45.159-5.864 2.979 2.979 0 0 0-.24-3.836 31.388 31.388 0 0 0-24.058-9.828 156.864 156.864 0 0 1 42.933-23.684A31.52 31.52 0 0 0 194.779 87.7a2.979 2.979 0 0 0 1.9-3.344 31.4 31.4 0 0 0-17.319-22.741 157.061 157.061 0 0 1 42.691-2A31.473 31.473 0 0 0 247.807 96.7a2.98 2.98 0 0 0 3.313-1.948 31.636 31.636 0 0 0-5.883-31.283 157.966 157.966 0 0 1 23.927 7.921zm91.991 291.523v84.854a7 7 0 0 1-14 0v-84.854a132.9 132.9 0 0 0-30.995 5.31v79.544a7 7 0 0 1-14 0v-74.508a133.312 133.312 0 0 0-31 18.467v56.041a7 7 0 1 1-14 0v-43.16a132.757 132.757 0 0 0-35.937 81.749 8.969 8.969 0 0 0 8.985 9.642H478.1a8.968 8.968 0 0 0 8.979-9.642 132.771 132.771 0 0 0-35.937-81.749v43.16a7 7 0 1 1-14 0v-56.041a133.208 133.208 0 0 0-31-18.467v74.508a7 7 0 0 1-14 0v-79.544a132.9 132.9 0 0 0-30.987-5.31z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                </g>
                                            </svg>
                                            <span>carbs</span>
                                        </span>
                                        <span class="d-flex align-items-center gap-2">
                                            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2246 19.7286C16.9485 22.6736 16.7547 27.8855 18.4936 30.104C18.4936 30.104 17.6751 24.3786 25.0133 17.1954C27.968 14.3036 28.651 10.3705 27.6193 7.42067C27.0332 5.74957 25.9627 4.36911 25.0327 3.4052C24.4902 2.83848 24.9068 1.90364 25.6963 1.93754C30.4722 2.15067 38.2126 3.47785 41.5015 11.7316C42.9449 15.3547 43.0515 19.099 42.3636 22.9061C41.9277 25.3377 40.3777 30.7433 43.9136 31.4069C46.4372 31.8816 47.6579 29.8763 48.2052 28.4329C48.4329 27.8322 49.2224 27.6821 49.6486 28.1616C53.9111 33.0102 54.2744 38.721 53.3929 43.6374C51.6879 53.1408 42.0633 60.0577 32.5018 60.0577C20.5571 60.0577 11.0488 53.2232 8.58333 40.8522C7.59037 35.8583 8.09412 25.9771 15.7957 19.0021C16.3672 18.4789 17.3021 18.9439 17.2246 19.7286V19.7286Z" fill="url(#paint0_radial_125_1107)" />
                                                <path d="M36.8651 37.5003C32.4622 31.8332 34.4336 25.3668 35.5137 22.7899C35.659 22.4508 35.2715 22.1311 34.9664 22.3394C33.0725 23.6279 29.1926 26.66 27.3859 30.9274C24.9398 36.6963 25.1142 39.5202 26.5625 42.9689C27.4344 45.0469 26.422 45.4877 25.9134 45.5652C25.4194 45.6427 24.964 45.3133 24.6008 44.9694C23.5557 43.9661 22.8109 42.6913 22.4501 41.2882C22.3726 40.9879 21.9803 40.9055 21.8011 41.1525C20.4448 43.0271 19.7425 46.035 19.7086 48.1614C19.602 54.7344 25.0319 60.0625 31.6 60.0625C39.878 60.0625 45.9084 50.9079 41.1519 43.2547C39.7714 41.0266 38.4733 39.5686 36.8651 37.5003Z" fill="url(#paint1_radial_125_1107)" />
                                                <defs>
                                                    <radialGradient id="paint0_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.136 60.2083) rotate(-179.751) scale(34.1909 56.1004)">
                                                        <stop offset="0.314" stop-color="#FF9800" />
                                                        <stop offset="0.662" stop-color="#FF6D00" />
                                                        <stop offset="0.972" stop-color="#F44336" />
                                                    </radialGradient>
                                                    <radialGradient id="paint1_radial_125_1107" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0553 26.1846) rotate(90.5787) scale(35.7742 26.9228)">
                                                        <stop offset="0.214" stop-color="#FFF176" />
                                                        <stop offset="0.328" stop-color="#FFF27D" />
                                                        <stop offset="0.487" stop-color="#FFF48F" />
                                                        <stop offset="0.672" stop-color="#FFF7AD" />
                                                        <stop offset="0.793" stop-color="#FFF9C4" />
                                                        <stop offset="0.822" stop-color="#FFF8BD" stop-opacity="0.804" />
                                                        <stop offset="0.863" stop-color="#FFF6AB" stop-opacity="0.529" />
                                                        <stop offset="0.91" stop-color="#FFF38D" stop-opacity="0.209" />
                                                        <stop offset="0.941" stop-color="#FFF176" stop-opacity="0" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                            <span>Fats</span>
                                        </span>
                                    </div>
                                    <h6>$20.99</h6>
                                </div>
                            </div>
                        </swiper-slide> -->
                    </swiper>

                </div>

              </div>
            </div>
          </div>
          <!-- <div class="offer-section fix bg-color2">
            <div class="offer-wrapper"> -->
                <!-- <div class="container">
                    <div class="row gy-4">
                        <div class="col-lg-6 col-xl-4">
                            <div class="offer-card style1" data-wow-delay="0.2s" style=" visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                                <div class="offer-content">
                                    <h6>ON THIS WEEK</h6>
                                    <h3>SPICY FRIED CHICKEN</h3>
                                    <p>limits Time Offer</p>
                                    <a href="menu.html" class="btn theme-btn style4">
                                        ORDER NOW <i class="fa-solid fa-arrow-right-long"></i>
                                    </a>
                                </div>
                                <div class="offer-thumb">
                                    <img class="thumbImg" src="@/assets/homepage/Cilantro_Chicken_with_Vinegar-removebg-preview (2).png" alt="thumb">
                                    <div class="shape float-bob-x"><img src="@/assets/homepage/offerShape1-4.png" alt="shape"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4">
                            <div class="offer-card style1" data-wow-delay="0.5s" style=" visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
                                <div class="offer-content">
                                    <h6>WELCOME FRESHEAT</h6>
                                    <h3>TODAY SPACIAL FOOD</h3>
                                    <p>limits Time Offer</p>
                                    <a href="menu.html" class="btn theme-btn style5">
                                        ORDER NOW <i class="fa-solid fa-arrow-right-long"></i>
                                    </a>
                                </div>
                                <div class="offer-thumb">
                                    <img class="thumbImg" src="@/assets/homepage/Pickled Veg Chicken Stir-fry.png" alt="thumb">
                                    <div class="shape float-bob-x"><img src="@/assets/homepage/offerShape1-4.png" alt="shape"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4">
                            <div class="offer-card style1 " data-wow-delay="0.7s" style=" visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
                                <div class="offer-content">
                                    <h6>ON THIS WEEK</h6>
                                    <h3>SPECIAL CHICKEN ROLL</h3>
                                    <p>limits Time Offer</p>
                                    <a href="menu.html" class="btn theme-btn style4">
                                        ORDER NOW <i class="fa-solid fa-arrow-right-long"></i>
                                    </a>
                                </div>
                                <div class="offer-thumb">
                                    <img class="thumbImg" src="@/assets/homepage/Cilantro_Chicken_with_Vinegar-removebg-preview (2).png" alt="thumb">
                                    <div class="shape float-bob-x"><img src="@/assets/homepage/offerShape1-4.png" alt="shape"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                
                 <!-- <div class="container container-card " >
                  <div class="row" > -->


                  <!--  <div class="col-xxl-4 col-xxl-4 col-lg-6 col-md-12 col-sm-12 col-12 p-3" >
                      <div class="Offer-green-card">
                          <div class="offer-detail-with-logo">
                              <div class="logo-container"> 
                                  <img src="../assets/homepage/chi-hun-da-su-logo-vertical-green.svg">
                              </div>

                              <div class="Offer-card-detail" >
                                <p> ON THIS WEEK</p>
                                <h4> Cashback Offer</h4>

                                <button type="button" class="btn btn-green-card">Order Now  &nbsp; <i class="fas fa-arrow-right"></i></button>
                              </div>
                          </div>

                          <div class="offer-detail-price">
                              <p class="small-text"> Limited Time Offer</p>
                              <h5> 50 <span> A$</span></h5>
                              <p class="cashback-text"> Cashback</p>
                          </div>

                      </div>
                    </div>

                    <div class=" col-xxl-4 col-xxl-4 col-lg-6 col-md-12 col-sm-12 col-12 p-3" >
                      <div class="Offer-green-card">
                          <div class="offer-detail-with-logo">
                              <div class="logo-container"> 
                                  <img src="../assets/homepage/dish-img-wrap.png">
                                  <p class=""> SPICY FRIED CHICKEN </p>
                              </div>

                              <div class="Offer-card-detail" >
                                <p> ON THIS WEEK</p>
                                <h4> Cashback Offer</h4>

                                <button type="button" class="btn btn-green-card">Order Now &nbsp; <i class="fas fa-arrow-right"></i></button>
                              </div>
                          </div>

                          <div class="offer-detail-price">
                              <p class="small-text"> Limited Time Offer</p>
                              <h5> 50 <span> A$</span></h5>
                              <p class="cashback-text"> Cashback</p>
                          </div>

                      </div>
                    </div>

                    <div class=" col-xxl-4 col-xxl-4 col-lg-6 col-md-12 col-sm-12 col-12 p-3" >
                      <div class="offer-brown-card">
                          <div class="offer-detail-with-logo">
                              <div class="logo-container"> 
                                  <img src="../assets/homepage/chi-hun-da-su-logo-vertical-brown.svg">
                      
                              </div>

                              <div class="Offer-card-detail" >
                                <p> ON THIS WEEK</p>
                                <h4> Cashback Offer</h4>

                                <button type="button" class="btn btn-green-card">Order Now &nbsp; <i class="fas fa-arrow-right"></i></button>
                              </div>
                          </div>

                          <div class="offer-detail-price">
                              <p class="small-text"> Limited Time Offer</p>
                              <h5> 50 <span> A$</span></h5>
                              <p class="cashback-text"> Cashback</p>
                          </div>

                      </div>
                    </div> -->

                    <!-- <div class=" col-xxl-4 col-xxl-4 col-lg-6 col-md-12 col-sm-12 col-12 p-3" >
                      <div class="offer-brown-card">
                          <div class="offer-detail-with-logo">
                              <div class="logo-container"> 
                                  <img src="../assets/homepage/dish-img-wrap.png">
                                  <p class=""> SPICY FRIED CHICKEN </p>
                              </div>

                              <div class="Offer-card-detail" >
                                <p> ON THIS WEEK</p>
                                <h4> Cashback Offer</h4>

                                <button type="button" class="btn btn-green-card">Order Now &nbsp; <i class="fas fa-arrow-right"></i></button>
                              </div>
                          </div>

                          <div class="offer-detail-price">
                              <p class="small-text"> Limited Time Offer</p>
                              <h5> 50 <span> A$</span></h5>
                              <p class="cashback-text"> Cashback</p>
                          </div>

                      </div>
                    </div>

                  </div>

                </div>
                
            </div>
        </div> -->
        <section class="about-us-section fix pb-0">
          <div class="about-wrapper style1">
            <div class="shape1 h-100 d-none d-xxl-block"><img src="../assets/homepage/Group-5.png" alt="shape"></div>
            <div class="shape2 h-100 d-none d-xxl-block"><img src="@/assets/homepage/aboutShape1_2.png" alt="shape"></div>
            <div class="shape3 h-100 d-none d-xxl-block"><img class="cir36" src="@/assets/homepage/Cilantro_Chicken_with_Vinegar-removebg-preview (2).png" alt="shape"></div>
            <div class="shape4 h-100 d-none d-xxl-block"><img src="../assets/homepage/Group-6.png" alt="shape"></div>
            <div class="shape5 h-100 d-none d-xxl-block"><img src="@/assets/homepage/aboutShape1_5.png" alt="shape"></div>
            <div class="shape6 h-100 d-none d-xxl-block"><img class="cir36" src="@/assets/homepage/Cilantro_Chicken_with_Vinegar-removebg-preview (2).png" alt="shape"></div>
            <div class="container">
              <div class="about-us pt-4 header-test-wrap section-padding">
                  <div class="row">
                      <div class="col-12">
                          <div class="title-area">
                            <div class="header-test-wrap">
                              <p><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_312_6)">
                                <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118"/>
                                <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_312_6">
                                <rect width="20" height="20" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                              </span> 
                              {{t('about_us', ['homepage'])}}
                                <span>
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_312_6)">
                                    <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118"/>
                                    <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_312_6">
                                    <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                </span>
                              </p>
                            </div>
                              <h2 class="title text-center mt-3" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
                                {{t('transform_your_body_elevate_your_health', ['homepage'])}}
                              </h2>
                              <div class="text" data-wow-delay="0.8s" style="visibility: visible; animation-delay: 0.8s; animation-name: fadeInUp;">{{t('about_us_description', ['homepage'])}}</div>
                              <div class="btn-wrapper mt-4 w-40" data-wow-delay="0.9s" style="visibility: visible; animation-delay: 0.9s; animation-name: fadeInUp;">
                                  
                                  <router-link class=" theme-btn btn mx-auto " to="/ordernow" active-class="active"  @click="closeOffcanvas" >{{t('order_now', ['homepage'])}}<i class="fa-solid fa-arrow-right-long"></i></router-link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>

    </section>

    <section class="popular-dishes-section fix section-padding">
        <div class="popular-dishes-wrapper style1">
            <div class="shape1 d-none d-xxl-block"><img src="@/assets/homepage/popularDishesShape1_1.png" alt="shape">
            </div>
            <div class="shape2 float-bob-y d-none d-xxl-block"><img src="@/assets/homepage/popularDishesShape1_2.png" alt="shape"></div>
            <div class="container">
                <div class="title-area header-test-wrap">
                    <div class="header-test-wrap">
                        <p><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_312_6)">
                                        <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118" />
                                        <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_312_6">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            {{t('popular_dishes', ['homepage'])}}
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_312_6)">
                                        <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118" />
                                        <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_312_6">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </p>
                    </div>
                    <h2 class="title text-center" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
                        {{t('best_selling_dishes', ['homepage'])}}
                    </h2>
                </div>
                <div class="dishes-card-wrap style1">
                    <div class="dishes-card style1" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;" v-for="item in best_sellings" :key="item.id">
                        <div class="dishes-thumb">
                            <img :src="getItemImage(item)" alt="thmb" class="rounded">
                        </div>
                        <router-link to="/ordernow">
                            <h3>{{ t(item.trans_code, ['menu_item']) }}</h3>
                        </router-link>
                        <p>{{ t('order_today', ['homepage']) }}</p>
                        <h6>{{item.price}}</h6>
                        <!-- <div class="social-profile">
                              <span class="plus-btn"> <a href="#"> <i class="fa-regular fa-heart"></i></a></span>
                              <ul>
                                  <li><a href="cart.html"><i class="fa-regular fa-basket-shopping-simple"></i></a></li>
                                  <li><a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="fa-light fa-eye"></i></a></li>
                              </ul>
                          </div> -->
                    </div>
                    <!-- <div class="dishes-card style1" data-wow-delay="0.4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;"> -->
                        <!-- <div class="social-profile">
                              <span class="plus-btn"> <a href="#"> <i class="fa-regular fa-heart"></i></a></span>
                              <ul>
                                  <li><a href="cart.html"><i class="fa-regular fa-basket-shopping-simple"></i></a></li>
                                  <li><a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="fa-light fa-eye"></i></a></li>
                              </ul>
                          </div> -->
                        <!-- <div class="dishes-thumb">
                            <img src="@/assets/homepage/dish-img-wrap.png" alt="thmb">
                        </div>
                        <a href="menu.html">
                            <h3>Chinese Pasta</h3>
                        </a>
                        <p>The registration fee</p>
                        <h6>$15.99</h6>
                    </div>
                    <div class="dishes-card style1" data-wow-delay="0.6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;"> -->
                        <!-- <div class="social-profile">
                              <span class="plus-btn"> <a href="#"> <i class="fa-regular fa-heart"></i></a></span>
                              <ul>
                                  <li><a href="cart.html"><i class="fa-regular fa-basket-shopping-simple"></i></a></li>
                                  <li><a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="fa-light fa-eye"></i></a></li>
                              </ul>
                          </div> -->
                        <!-- <div class="dishes-thumb">
                            <img src="@/assets/homepage/dish-img-wrap.png" alt="thmb">
                        </div>
                        <a href="menu.html">
                            <h3>Chicken Pizza</h3>
                        </a>
                        <p>The registration fee</p>
                        <h6>$26.99</h6>
                    </div>
                    <div class="dishes-card style1" data-wow-delay="0.8s" style="visibility: visible; animation-delay: 0.8s; animation-name: fadeInUp;"> -->
                        <!-- <div class="social-profile">
                              <span class="plus-btn"> <a href="#"> <i class="fa-regular fa-heart"></i></a></span>
                              <ul>
                                  <li><a href="cart.html"><i class="fa-regular fa-basket-shopping-simple"></i></a></li>
                                  <li><a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="fa-light fa-eye"></i></a></li>
                              </ul>
                          </div> -->
                          <!-- <div class="dishes-thumb">
                              <img src="@/assets/homepage/dish-img-wrap.png" alt="thmb">
                          </div>
                          <a href="menu.html">
                              <h3>Chicken Noodles</h3>
                          </a>
                          <p>The registration fee</p>
                          <h6>$39.00</h6>
                      </div> -->
                   
                  </div>
                  <div class="btn-wrapper w-30 text-center mt-5" data-wow-delay="0.9s" style="visibility: visible; animation-delay: 0.9s; animation-name: fadeInUp;">
              
                    <router-link class=" theme-btn btn mx-auto " to="/ordernow" active-class="active"  @click="closeOffcanvas" >{{t('order_now', ['homepage'])}} <i class="fa-solid fa-arrow-right-long"></i></router-link>

                </div>
            </div>
        </div>

    </section>
    <section class="cta-section fix">
        <div class="cta-wrapper style1  section-padding">
            <div class="shape1 float-bob-x d-none d-xxl-block"><img src="@/assets/homepage/ctaShape1_1.png" alt="shape">
            </div>
            <div class="shape2 float-bob-y d-none d-xxl-block"><img src="@/assets/homepage/ctaShape1_2.png" alt="shape">
            </div>
            <div class="shape3 float-bob-y d-none d-xxl-block"><img class="img-wrap-shape" src="@/assets/homepage/ctaShape1_3.png" alt="shape">
            </div>
            <div class="container">
                <div class="cta-wrap style1">
                    <div class="row">

                        <div class="col-xl-7 order-2 order-xl-1">
                            <div class="cta-content">
                                <h6 class="wow fadeInUp" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">{{t('welcome_chi_hun_da_su', ['homepage'])}}</h6>
                                <h3 class="wow fadeInUp" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">{{t('be_a_member_with_chds', ['homepage'])}}</h3>
                                <p class="wow fadeInUp" data-wow-delay="0.8s" style="visibility: visible; animation-delay: 0.8s; animation-name: fadeInUp;"> {{t('open_exclusive_deals_and_meal_plan', ['homepage'])}}</p>
                                <!-- <button class="btn theme-btn" type="button"> Order Now<span class="round-arrow ms-2"><i class="fa-solid fa-arrow-right-long"></i></span></button> -->
                                <router-link type="button" class="theme-btn btn " to="/ordernow" active-class="active"  @click="closeOffcanvas" >{{t('order_now', ['homepage'])}} <i class="fa-solid fa-arrow-right-long"></i></router-link>

                            </div>
                        </div>
                        <div class="col-xl-5 order-1 order-xl-2">
                            <div class="cta-thumb">
                                <img class="img-fluid float-bob-x" src="@/assets/homepage/45img.png" alt="thumb">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <section class="popular-dishes-section food-menu-div fix section-padding pb-0">

        <div class="container">
            <div class="food-menu-tab-wrapper style-bg">
                <div class="popular-dishes-wrapper style1 food-menu-tab">
                <div class="title-area header-test-wrap">
                  <div class="header-test-wrap">
                    <p><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_312_6)">
                      <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118"/>
                      <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_312_6">
                      <rect width="20" height="20" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>
                    </span> 
                      {{ t('food_menu', ['homepage'])}}
                      <span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_312_6)">
                          <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118"/>
                          <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_312_6">
                          <rect width="20" height="20" fill="white"/>
                          </clipPath>
                          </defs>
                          </svg>
                      </span>
                    </p>
                  </div>
                    <h2 class="title text-center" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
                      {{ t('chi_hun_da_su_foods_menu', ['homepage']) }}
                    </h2>
                </div>
                <div class="row ">
                          <div class="col-lg-6 col-md-12 col-sm-12 col-12 p-3">
                              <div class="single-menu-items p-3">
                                  <div class="details">
                                      <div class="menu-item-thumb"><img src="@/assets/homepage/dish-img-wrap.png" alt="thumb"></div>
                                      <div class="menu-content">
                                          <router-link to="/ordernow">
                                              <h3 class="active">{{t('chicken_stuffed_bean_curd_rolls',['menu_item'])}}</h3>
                                          </router-link>
                                          <h6>A$19.00 – A$31.00</h6>
                                          <!-- <p>Minced chicken wrapped in soft bean curd sheets, steamed with ginger and seasonings. Tender, juicy, and packed with flavor, this is a healthy, light meal option.</p> -->
                                      </div>
                                  </div>

                                  
                              </div>
                          </div>

                          <div class="col-lg-6 col-md-12 col-sm-12 col-12 p-3">
                              <div class="single-menu-items p-3">
                                  <div class="details">
                                      <div class="menu-item-thumb"><img src="@/assets/homepage/dish-img-wrap.png" alt="thumb"></div>
                                      <div class="menu-content">
                                          <router-link to="/ordernow">
                                              <h3 class="active">{{t('cilantro_chicken_with_vinegar',['menu_item'])}}</h3>
                                          </router-link>
                                          <h6>A$19.00 – A$31.00</h6>
                                          <!-- <p>Chicken breast mixed with cilantro, vinegar, chili, soy sauce, and oyster sauce. This tangy, refreshing dish is high in protein, perfect for fitness diets and light daily meals.</p> -->
                                      </div>
                                  </div>

                                  
                              </div>
                          </div>

                          <div class="col-lg-6 col-md-12 col-sm-12 col-12 p-3">
                              <div class="single-menu-items p-3">
                                  <div class="details">
                                      <div class="menu-item-thumb"><img src="@/assets/homepage/dish-img-wrap.png" alt="thumb"></div>
                                      <div class="menu-content">
                                          <router-link to="/ordernow">
                                              <h3 class="active">{{t('honey_teriyaki_chicken_breast',['menu_item'])}}</h3>
                                          </router-link>
                                          <h6>A$19.00 – A$31.00</h6>
                                          <!-- <p>Pan-seared chicken breast with a honey-soy glaze, crispy outside, tender inside, a perfect healthy and flavorful option.</p> -->
                                      </div>
                                  </div>

                                  
                              </div>
                          </div>

                          <div class="col-lg-6 col-md-12 col-sm-12 col-12 p-3">
                              <div class="single-menu-items p-3">
                                  <div class="details">
                                      <div class="menu-item-thumb"><img src="@/assets/homepage/dish-img-wrap.png" alt="thumb"></div>
                                      <div class="menu-content">
                                        <router-link to="/ordernow">
                                              <h3 class="active">{{t('korean_spicy_chicken_breast',['menu_item'])}}</h3>
                                        </router-link>
                                          <h6>A$19.00 – A$31.00</h6>
                                          <!-- <p>Pan-seared chicken breast with Korean spicy sauce, blending sweetness and heat for a tender and flavorful dish.</p> -->
                                      </div>
                                  </div>

                                  
                              </div>
                          </div>


                          <div class="col-lg-6 col-md-12 col-sm-12 col-12 p-3">
                              <div class="single-menu-items p-3">
                                  <div class="details">
                                      <div class="menu-item-thumb"><img src="@/assets/homepage/dish-img-wrap.png" alt="thumb"></div>
                                      <div class="menu-content">
                                        <router-link to="/ordernow">
                                              <h3 class="active">{{t('wine_marinated_chicken_gizzards',['menu_item'])}}</h3>
                                        </router-link>
                                          <h6>A$19.00 – A$31.00</h6>
                                          <!-- <p>Crisp and tender chicken gizzards marinated in wine sauce, full of flavor. Perfect as a snack or appetizer.</p> -->
                                      </div>
                                  </div>

                                 
                              </div>
                          </div>


                          <div class="col-lg-6 col-md-12 col-sm-12 col-12 p-3">
                              <div class="single-menu-items p-3">
                                  <div class="details">
                                      <div class="menu-item-thumb"><img src="@/assets/homepage/dish-img-wrap.png" alt="thumb"></div>
                                      <div class="menu-content">
                                        <router-link to="/ordernow">
                                              <h3 class="active">{{t('pickled_veg_chicken_stir_fry',['menu_item'])}}</h3>
                                        </router-link>
                                          <h6>A$17.00 – A$31.00</h6>
                                          <!-- <p>It's a testament to our.</p> -->
                                      </div>
                                  </div>

                                  
                              </div>
                          </div>

                      </div>
            </div>
        </div>
        </div>

    </section>
    <section class="testimonial-section fix bg-color3">
        <div class="testimonial-wrapper style1 section-padding ">
            <!-- <div class="shape"><img src="@/assets/homepage/testmonial-img.png" alt="thumb"></div>
            <div class="shape2"><img src="assets/img/shape/testimonialShape1_1.png" alt="shape"></div> -->
            <div class="container mb-5">

                <div class="title-area header-test-wrap">
                    <div class="header-test-wrap">
                        <p><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_312_6)">
                                        <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118"></path>
                                        <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_312_6">
                                            <rect width="20" height="20" fill="white"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            {{t('testimonials', ['homepage'])}}
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_312_6)">
                                        <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118"></path>
                                        <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_312_6">
                                            <rect width="20" height="20" fill="white"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </p>
                    </div>
                    <h2 class="title text-center" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
                        {{t('what_clients_say_about_us', ['homepage'])}}
                    </h2>
                </div>
                <div class="over-flow">

                    <div class="swiper-container-2 ">

                        <swiper :loop="true" :breakpoints="{
                                  '640': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                  },
                                  '768': {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                  },
                                  '1024': {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                  },
                                  '1400': {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                  },

                                  '2000': {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                  },

                                  '2500': {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                  },
                                }" :modules="modules" :pagination="true" :navigation="true" class="testimonialSwiper" @swiper="onSwiper" @slideChange="onSlideChange">
                            <swiper-slide>
                                <div class="testimonial-card style1">
                                    <div class="testimonial-header">
                                        <div class="fancy-box">
                                            <!-- <div class="item1"><img src="@/assets/homepage/testimonialProfile1_1.png" alt="thumb"></div> -->
                                            <div class="item2">
                                                <h6>Shengli</h6>
                                                <!-- <p>Web Designer</p> -->
                                                <div class="star-rating">
                                                    <i class="fa fa-star px-1" data-value="1"></i>
                                                    <i class="fa fa-star px-1" data-value="2"></i>
                                                    <i class="fa fa-star px-1" data-value="3"></i>
                                                    <i class="fa fa-star px-1" data-value="4"></i>
                                                    <i class="fa fa-star px-1" data-value="5"></i>
                                                </div>
                                            </div>
                                            <div class="quote"><img class="width-quote" src="@/assets/homepage/quote.svg" alt="icon">
                                            </div>
                                        </div>
                                    </div>

                                    <p>
                                        These meals have become my go-to daily choice for healthy eating. They taste amazing and are genuinely nutritious, which has made sticking to a good diet so much easier. I feel more energized, and it’s been great not having to worry about meal prep or clean-up!
                                    </p>
                                </div>
                            </swiper-slide>

                            <swiper-slide>
                                <div class="testimonial-card style1">
                                    <div class="testimonial-header">
                                        <div class="fancy-box">
                                            <!-- <div class="item1"><img src="@/assets/homepage/testimonialProfile1_1.png" alt="thumb"></div> -->
                                            <div class="item2">
                                                <h6>Xiao Hai</h6>
                                                <!-- <p>Web Designer</p> -->
                                                <div class="star-rating">
                                                    <i class="fa fa-star px-1" data-value="1"></i>
                                                    <i class="fa fa-star px-1" data-value="2"></i>
                                                    <i class="fa fa-star px-1" data-value="3"></i>
                                                    <i class="fa fa-star px-1" data-value="4"></i>
                                                    <i class="fa fa-star px-1" data-value="5"></i>
                                                </div>
                                            </div>
                                            <div class="quote"><img class="width-quote" src="@/assets/homepage/quote.svg" alt="icon">
                                            </div>
                                        </div>
                                    </div>

                                    <p>
                                        The beef shank is incredibly tasty! Tender and flavorful—I can’t get enough of it~
                                    </p>
                                </div>
                            </swiper-slide>

                            <swiper-slide>
                                <div class="testimonial-card style1">
                                    <div class="testimonial-header">
                                        <div class="fancy-box">
                                            <!-- <div class="item1"><img src="@/assets/homepage/testimonialProfile1_1.png" alt="thumb"></div> -->
                                            <div class="item2">
                                                <h6>Zarya</h6>
                                                <!-- <p>Web Designer</p> -->
                                                <div class="star-rating">
                                                    <i class="fa fa-star px-1" data-value="1"></i>
                                                    <i class="fa fa-star px-1" data-value="2"></i>
                                                    <i class="fa fa-star px-1" data-value="3"></i>
                                                    <i class="fa fa-star px-1" data-value="4"></i>
                                                    <i class="fa fa-star px-1" data-value="5"></i>
                                                </div>
                                            </div>
                                            <div class="quote"><img class="width-quote" src="@/assets/homepage/quote.svg" alt="icon">
                                            </div>
                                        </div>
                                    </div>

                                    <p>
                                        This meal plan is incredibly convenient, and it’s been life-changing! After just a month, I’ve already lost eight kilos, and it didn’t even feel like I was dieting. The meals are balanced, satisfying, and super easy to stick to—plus, everything tastes great. It’s effortless weight loss!
                                    </p>
                                </div>
                            </swiper-slide>

                            <swiper-slide>
                                <div class="testimonial-card style1">
                                    <div class="testimonial-header">
                                        <div class="fancy-box">
                                            <!-- <div class="item1"><img src="@/assets/homepage/testimonialProfile1_1.png" alt="thumb"></div> -->
                                            <div class="item2">
                                                <h6>Zuyi</h6>
                                                <!-- <p>Web Designer</p> -->
                                                <div class="icon"><img src="@/assets/homepage/star.svg" alt="icon"></div>
                                            </div>
                                            <div class="quote"><img class="width-quote" src="@/assets/homepage/quote.svg" alt="icon">
                                            </div>
                                        </div>
                                    </div>

                                    <p>
                                        I bought it as a regular meal, and somehow, the weight just started coming off. It’s very delicious also.
                                    </p>
                                </div>
                            </swiper-slide>

                            <swiper-slide>
                                <div class="testimonial-card style1">
                                    <div class="testimonial-header">
                                        <div class="fancy-box">
                                            <!-- <div class="item1"><img src="@/assets/homepage/testimonialProfile1_1.png" alt="thumb"></div> -->
                                            <div class="item2">
                                                <h6>Chang</h6>
                                                <!-- <p>Web Designer</p> -->
                                                <div class="icon"><img src="@/assets/homepage/star.svg" alt="icon"></div>
                                            </div>
                                            <div class="quote"><img class="width-quote" src="@/assets/homepage/quote.svg" alt="icon">
                                            </div>
                                        </div>
                                    </div>

                                    <p>
                                        As someone with a busy work schedule, having these healthy, ready-made meals has been a game-changer. I get to enjoy a fresh, balanced meal every day without taking time to cook or prep. It’s so convenient, saving me time and energy, and the food is very good for asian stomach.
                                    </p>
                                </div>
                            </swiper-slide>

                            <swiper-slide>
                                <div class="testimonial-card style1">
                                    <div class="testimonial-header">
                                        <div class="fancy-box">
                                            <!-- <div class="item1"><img src="@/assets/homepage/testimonialProfile1_1.png" alt="thumb"></div> -->
                                            <div class="item2">
                                                <h6>Albert Flores</h6>
                                                <p>Web Designer</p>
                                                <div class="icon"><img src="@/assets/homepage/star.svg" alt="icon"></div>
                                            </div>
                                            <div class="quote"><img class="width-quote" src="@/assets/homepage/quote.svg" alt="icon">
                                            </div>
                                        </div>
                                    </div>

                                    <p>Penatibus magnis dis point parturient montes nascetur ridiculus mus Ut id
                                        lorem ac enim the vestibulum blandit nec sit amet felis. Fusce quis diam
                                        odio Cras mattis mi quis tincidunt
                                    </p>
                                </div>
                            </swiper-slide>

                        </swiper>

                    </div>

                </div>

            </div>

        </div>

    </section>
</div>

</template>

<style scoped>
   .star-rating .fa-star {
      font-size: 1rem;
      color: #ffa41b;
      cursor: pointer;
      transition: color 0.2s;
    }

</style>
